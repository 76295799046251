// Fonts
@import url('https://fonts.bunny.net/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
//@import 'bootstrap/scss/bootstrap';

// Import styles
@import "~@coreui/coreui/scss/coreui";

// DataTables
@import "~datatables.net-bs4/css/dataTables.bootstrap4.css";
@import "~datatables.net-buttons-bs4/css/buttons.bootstrap4.css";
@import "~select2/dist/css/select2.min.css";

.typeahead {
    background-color: #fff;
}

.typeahead:focus {
    border: 2px solid #0097cf;
}

.tt-query {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
    color: #999
}

.tt-menu {
    width: 422px;
    margin: 12px 0;
    padding: 8px 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);
    -moz-box-shadow: 0 5px 10px rgba(0,0,0,.2);
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
}

.tt-suggestion {
    padding: 3px 20px;
    font-size: 18px;
    line-height: 24px;
    display: block;
}

.tt-suggestion:hover {
    cursor: pointer;
    color: #fff;
    background-color: #321fdb;
}

.tt-suggestion.tt-cursor {
    color: #fff;
    background-color: #321fdb;
}

.tt-suggestion p {
    margin: 0;
}

#bloodhound .empty-message {
    padding: 5px 10px;
    text-align: center;
}
